@keyframes expandX {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

@keyframes expandY {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

.scale-size-x.animated {
  animation: expandX 2.5s forwards;
}

.scale-size-y.animated {
  animation: expandY 2.5s forwards;
}
.image-slider-container {
  position: relative;
  height: 525px;
  width: 1300px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  max-height: 35vw;
  max-width: 95vw;
  overflow: hidden;
}

.image-slider {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}

.slide-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide-image.active {
  opacity: 1;
}

@media (max-width: 1200px) {
  .image-slider-container {
    height: 480px;
    width: 90vw;
  }
}

@media (max-width: 600px) {
  .image-slider-container {
    height: 290px;
    width: 95vw;
  }
}
