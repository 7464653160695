body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    to bottom,
    #e0e0e099,
    #ffffff,
    #ffffff,
    #e0e0e099
  );
  background-size: 100% 2300px; /* 100% ширини і 1200px висоти для градієнта */
  background-repeat: repeat-y; /* Повторювати градієнт по вертикалі */
  margin: 0;
  padding: 0;
  height: 100vh; /* Забезпечити висоту на весь екран */
  box-sizing: border-box;
}

.form-input {
  background-color: #f7f7f7 !important;
  border-radius: 15px !important;
  border-width: 0px !important;
}

.phone-number {
  padding: 0;
  cursor: pointer !important;
  text-wrap: nowrap;
  min-width: fit-content !important;
}

.media-container {
  position: relative;
  border-radius: 30px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  width: 90vw;
  height: calc(90vw / 2.2);
  overflow: hidden;
}

.big-text {
  font-size: clamp(23px, 2.5vw, 36px);
}
.text {
  font-size: clamp(12px, 1.5vw, 18px);
}

h1 {
  font-size: 52px !important;
  font-weight: 600;
}
h2 {
  font-size: 20px !important;
}
h3 {
  font-size: 16px !important;
  font-weight: 400 !important;
}
.map-container {
  max-width: 40vw !important;
  height: 500px;
  width: 600px;
  max-height: 50vh;
  border-radius: 30px;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

@media (max-width: 991px) {
  .map-container {
    max-width: 90vw !important;
    margin-bottom: -30px;
  }
}
@media (max-width: 768px) {
  h1 {
    font-size: 42px !important;
  }
  h2 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 14px !important;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 26px !important;
  }
  h2 {
    font-size: 11px !important;
  }
  h3 {
    font-size: 10px !important;
  }
  .logo-container {
    height: 40px;
    width: 102px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Стилі для скроллбара */
::-webkit-scrollbar {
  width: 0px;
  height: 13px;
}

::-webkit-scrollbar-thumb {
  background: #0d1c45; /* Колір пальця скроллбара */
  border: 2px solid #ffffff;
  border-radius: 100px;
}

.custom-select option {
  padding: 20px; /* Відступи всередині опцій */
  background-color: #eaeaea; /* Білий фон для опцій */
  color: #000000; /* Колір тексту */
  height: 40px !important;
}

.topText {
  text-shadow: 1.5px 1.5px 3px #00000099;
}
