.contacts-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.contacts-col {
  flex-basis: 280px;
  flex-grow: 1;
  max-width: 320px; /* максимальна ширина елемента */
  min-width: 280px; /* мінімальна ширина елемента */
}

.contacts-card {
  width: 100%;
}
