.additional-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}

.additional-title {
  font-size: clamp(16px, 2.5vw, 36px);
  font-weight: 700;
  display: flex;
  text-wrap: nowrap;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.blue-text {
  color: #1500ff;
}

.desc-text {
  color: #514f6e;
  font-size: clamp(11px, 1.75vw, 24px);
  margin-top: 10px;
  font-weight: 500;
}

.container-additional {
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: 1px solid #dfdfdf;
}

/* Видалення лівого бордера у першого елемента в кожному рядку */
.container-additional:nth-child(3n + 1) {
  border-left: none;
}

/* Центрування останнього елемента */

/* Видалення зайвих бордерів у першого і останнього елементів */
.container-additional:first-child {
  border-left: none;
}
.hide-small {
  display: none;
}
@media (max-width: 1000px) {
  .additional-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .hide-big {
    display: none;
  }
  .hide-small {
    display: block;
  }

  .container-additional {
    border-left: 0;
    border-bottom: 0;
  }

  .container-additional:nth-last-child(1) {
    grid-column: 1 / -1;
  }
}
@media (max-width: 768px) {
  .additional-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .container-additional:nth-last-child(1) {
    grid-column: 1 / -1;
  }
}
