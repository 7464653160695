.header-logo {
  width: 176px;
  height: 66px;
  justify-self: center;
  align-self: center;
}

.header-container {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  position: sticky;
  top: 0;
  justify-content: center;
  align-items: center;
  max-width: 99.9vw;
  flex-direction: column;
  gap: 5px;
  z-index: 998;
}
.scaleX {
  transform: scaleY(0, 1);
}
.phones-container {
  align-self: center !important;
}
.lang-container {
  padding-top: 15px;
  display: grid;
  margin-top: auto;
  z-index: 500 !important;
}
.phones-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly !important;
  gap: 10px;
  margin-bottom: 10px;
}

.phone-number {
  display: flex;
  gap: 5px;
  font-weight: 600;
  color: #8b0000;
  max-width: 150px !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.phone-number img {
  height: 14px;
  width: 14px;
}

.buttons-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  background-color: #eaeaea;
  border-radius: 15px;
  padding: 5px;
  z-index: 200 !important;
  margin-left: auto;
  margin-right: auto;
}

.buttons-conteiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px;
}

.button-wrapper {
  position: relative;
}

.nav-button {
  border: none !important;
  background: none !important;
  font-size: 16px !important;
  z-index: 1 !important;
  cursor: pointer;
  outline: none !important;
  color: #0d1c45 !important;
}

.nav-button.active,
.nav-button:hover {
  z-index: 1 !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: 'transparent' !important;
}

.active-indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 16px !important;
  right: 0;
  top: 0;
  background-color: #0d1c45;
  border-radius: 15px;
  color: #fff;
  padding-top: 6px;
  text-align: center;
}

.logo-container {
  display: grid;
  padding-top: 5px;
}
.langs-container {
  transform: scale(1) translate(-100%, -100%);
  z-index: 500 !important;
}

.lang-item {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 97px;
  z-index: 999 !important;
  text-align: center;
  cursor: pointer;
  font-size: clamp(11px, 2.2vw, 14px) !important;
}

@media (max-width: 1000px) {
  .phone-number {
    width: 50% !important;
  }
}
@media (max-width: 767px) {
  .header-logo {
    width: 159px;
    height: 55px;
  }

  .lang-item {
    width: 65px;
  }

  .nav-button {
    padding-left: 2px !important;
    padding-right: 2px !important;

    font-size: clamp(
      11px,
      2.2vw,
      16px
    ) !important; /* Мінімум 14px, середнє 2.2vw, максимум 18px */
  }

  .active-indicator {
    font-size: clamp(
      11px,
      2.2vw,
      16px
    ) !important; /* Мінімум 14px, середнє 2.2vw, максимум 18px */
  }
  .phones {
    flex-direction: column;
  }

  .phone-number {
    justify-content: center;
  }

  .header-logo {
    text-align: center;
  }
  .phones-container {
    order: -1;
  }
}

@media (max-width: 567px) {
  .header-logo {
    width: 102px;
    height: 40px;
  }
  .lang-item {
    width: 54px;
  }
  .buttons-conteiner {
    position: sticky !important;
    top: 0 !important;
  }
  .header-container {
    position: relative;
  }
}
