.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  position: fixed !important;
  top: 0;
  left: 0;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  transition: opacity 3.5s ease-out;
  opacity: 1;
  z-index: 1000;
}

.loader.hidden {
  opacity: 0;
  pointer-events: none;
}
