/* styles.css */
.consult-block-container {
  padding-left: 11vw;
  padding-right: 11vw;
}

@media (max-width: 576px) {
  .consult-block-container {
    padding: 20px;
    padding-top: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
