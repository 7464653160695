@keyframes slideLeft {
  from {
    transform: translateX(-130%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(130%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideUp {
  from {
    transform: translateY(130%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slideDown {
  from {
    transform: translateY(-130%);
  }
  to {
    transform: translateY(0);
  }
}

.animate {
  opacity: 0;
  visibility: hidden;
}

.animated {
  opacity: 1;
  visibility: visible;
}

.slide-left.animated {
  animation: slideLeft 1.3s ease-in-out forwards;
}
.slide-right.animated {
  animation: slideRight 1.3s ease-in-out forwards;
}
.slide-up.animated {
  animation: slideUp 1.3s ease-in-out forwards;
}
.slide-down.animated {
  animation: slideDown 1.3s ease-in-out forwards;
}
.adv-lg {
  height: max-content !important;
  overflow: visible !important;
}
.adv-sm {
  height: 0 !important;
  overflow: hidden !important;
}

@media (max-width: 992px) {
  .md-hide {
    width: 0 !important;
  }
}
@media (max-width: 768px) {
  .md-hide {
    width: 1px !important;
  }
  .sm-hide {
    width: 0px !important;
  }
  .adv-lg {
    height: 0 !important;
    overflow: hidden !important;
  }
  .adv-sm {
    height: max-content !important;
    overflow: visible !important;
  }
  .single-adv {
    border-width: 0px !important;
    padding: 20px 20px !important;
  }
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.company-desc-item {
  font-size: clamp(11px, 2.2vw, 16px) !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 50px);
  grid-template-rows: repeat(auto-fill, 50px);
  width: 100%;
  height: 100%;
  position: relative;
}

.grid-cell {
  width: 49px;
  height: 49px;
  background-color: #dbdbdb90;
  transition: opacity 3s ease-in-out;
  opacity: 0.35;
}

.grid-cell.active {
  opacity: 0.999;
}
