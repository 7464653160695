.partners-title {
  font-size: 52px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: "62px";
}

.partners-subtitle {
  font-size: 20px;
  margin-bottom: 50px;
}

.partner-logo {
  max-height: 100px;
  max-width: 100%;
}

.vertical-line {
  position: absolute;
  left: 0;
  height: 75px;
  width: 1px;
  background-color: #d2d5d7;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .partner-logo {
    max-height: 50px;
    max-width: "45%";
  }
}
